import React from 'react'
import { Box } from 'pcln-design-system'
import { AppDownload } from '@pcln/vip'
import analytics from '@/shared-utils/analytics'

type AppDownloadWrapperProps = {
  category: string
}

function AppDownloadWrapper({ category }: AppDownloadWrapperProps) {
  const gaAppDownload = (device: string) => {
    analytics.fireEvent({
      action: 'app download',
      category,
      label: device
    })
  }

  return (
    <Box
      color="background.light"
      boxShadowSize="sm"
      borderRadius="xl"
      pr={[3, null, null, null, 4, 5]}
      pl={4}
    >
      <AppDownload gaTracking={gaAppDownload} />
    </Box>
  )
}

export default AppDownloadWrapper
