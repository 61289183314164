import { BLOCKS, Document } from '@contentful/rich-text-types'

const headline: Document = {
  data: {},
  content: [
    {
      data: {},
      content: [
        {
          data: {},
          marks: [],
          value: 'Sign up for Exclusive Email-only Coupons',
          nodeType: 'text'
        }
      ],
      nodeType: BLOCKS.PARAGRAPH
    }
  ],
  nodeType: BLOCKS.DOCUMENT
}

const subheadline: Document = {
  data: {},
  content: [
    {
      data: {},
      content: [
        {
          data: {},
          marks: [],
          value: 'Exclusive access to coupons, special offers and promotions.',
          nodeType: 'text'
        }
      ],
      nodeType: BLOCKS.PARAGRAPH
    }
  ],
  nodeType: BLOCKS.DOCUMENT
}

const successHeadline: Document = {
  data: {},
  content: [
    {
      data: {},
      content: [
        {
          data: {},
          marks: [],
          value: 'Success! New Deals are on their way to your inbox.',
          nodeType: 'text'
        }
      ],
      nodeType: BLOCKS.PARAGRAPH
    }
  ],
  nodeType: BLOCKS.DOCUMENT
}

export default {
  headline,
  subheadline,
  alertId: '1',
  channelType: 'EMAIL',
  contactSourceType: 'CS',
  buttonText: 'send me deals',
  invalidEmailError: new Error('Email address is invalid'),
  url: '/partner/api/emailOptIn',
  placeholder: 'example@example.com',
  successHeadline,
  googleAnalyticsTrackingOnView: {
    action: null,
    category: null,
    label: null
  },
  googleAnalyticsTrackingOnClick: {
    action: null,
    category: null,
    label: null
  }
} as const
