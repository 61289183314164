import analytics from '@/shared-utils/analytics'
import isAbortSignalTimeoutSupported from '@/shared-utils/is-abortSignal-supported'

async function submitEmail(
  url: string,
  emailAddress: string,
  sourceId: string,
  alertId?: string,
  channelType?: string,
  contactSourceType?: string
) {
  const submission = {
    alertSubscribeList: [
      {
        emailAddress,
        sourceId,
        alertId,
        channelType,
        contactSourceType,
        partnerCode: 'PCLN'
      }
    ]
  }

  try {
    const response = await fetch(url, {
      signal: isAbortSignalTimeoutSupported()
        ? AbortSignal.timeout(300)
        : undefined,
      method: 'POST',
      body: JSON.stringify(submission),
      headers: { 'content-type': 'application/json' }
    })

    const { status, statusText, type } = response
    if (!response.ok) {
      const logObject = {
        response: {
          status,
          payload: submission,
          method: 'POST',
          statusText,
          type
        }
      }
      analytics.logError({
        message: `Email registration response status is > 299. ${JSON.stringify(
          logObject
        )}`
      })
      throw new Error(response.statusText)
    }

    const body = await response.json()
    if (!body.success) {
      throw new Error(body.errorDesc || 'Email registration failure')
    }
  } catch (error) {
    const errorLogObject = {
      url,
      emailAddress,
      payload: submission,
      method: 'POST',
      error
    }
    if (error instanceof Error && error.name === 'TimeoutError') {
      analytics.logError({
        message: `Timeout reached while invoking Email Registration API. ${JSON.stringify(
          errorLogObject
        )}`
      })
    } else {
      analytics.logError({
        message: `Email registration failure - An unexpected error occurred: ${JSON.stringify(
          errorLogObject
        )}`
      })
      throw error
    }
  }
}

export default submitEmail
