import React, { useState, useEffect } from 'react'
import { ContentfulRichText, IOptInType } from '@pcln/contentful-components'
import analytics from '@/shared-utils/analytics'
import useContentfulVariation from '@/hooks/useContentfulVariation'
import {
  OptInCard,
  NOT_SIGNED_UP,
  SIGN_UP_ERROR,
  SIGN_UP_IN_PROGRESS,
  SIGNED_UP,
  EMAIL_TYPE
} from '@pcln/marketing'
import { hasContentTypeId } from '@/shared-utils/content-model-type-guards'
import styled from 'styled-components'
import { Heading, Relative, theme } from 'pcln-design-system'
import CONTENT from './content'
import submitEmail from './utils'

type EmailSignup = {
  bfcmEmailOptIn?: boolean
  sourceId: string
  category?: string
}

const BFCMEmailWrapper = styled(Relative)`
  input {
    background-color: ${theme.colors.white};
    padding-left: 12px;
  }
`

function mapContentfulContent(optIn: IOptInType) {
  const { url, label, analyticsTracking } = optIn.callToAction ?? {}
  const { placeholder, error } = optIn?.input?.[0] ?? {}
  const {
    headline = CONTENT.headline,
    subheadline = CONTENT.subheadline,
    successHeadline = CONTENT.successHeadline,
    alertId = CONTENT.alertId,
    channelType = CONTENT.channelType,
    contactSourceType = CONTENT.contactSourceType,
    googleAnalyticsTracking
  } = optIn

  return {
    headline,
    subheadline,
    successHeadline,
    placeholder: placeholder ?? CONTENT.placeholder,
    invalidEmailError: error ?? CONTENT.invalidEmailError,
    buttonText: label ?? CONTENT.buttonText,
    url: url ?? CONTENT.url,
    alertId,
    channelType,
    contactSourceType,
    googleAnalyticsTrackingOnView: {
      category: googleAnalyticsTracking?.category ?? 'email opt-in',
      action: googleAnalyticsTracking?.action,
      label: googleAnalyticsTracking?.label
    },
    googleAnalyticsTrackingOnClick: {
      category: analyticsTracking?.category,
      action: analyticsTracking?.action,
      label: analyticsTracking?.label
    }
  }
}

function isIOptInType(content: unknown): content is IOptInType {
  return hasContentTypeId(content) && content.contentTypeId === 'optIn'
}

export default function EmailSignUp({
  bfcmEmailOptIn: bcfmEmailOptIn = false,
  sourceId,
  category = ''
}: EmailSignup) {
  const optIn = useContentfulVariation('emailOptIn', isIOptInType)
  const hasContentfulData = optIn != null
  const {
    headline,
    subheadline,
    successHeadline,
    placeholder,
    invalidEmailError,
    buttonText,
    url = CONTENT.url,
    alertId,
    channelType,
    contactSourceType,
    googleAnalyticsTrackingOnView,
    googleAnalyticsTrackingOnClick
  } = hasContentfulData ? mapContentfulContent(optIn) : CONTENT
  const {
    action: gaActionOnView,
    category: gaCategoryOnView,
    label: gaLabelOnView
  } = googleAnalyticsTrackingOnView

  const [signUpState, setSignUpState] = useState(NOT_SIGNED_UP)
  const submitHandler = async (data: { email: string }) => {
    try {
      setSignUpState(SIGN_UP_IN_PROGRESS)
      await submitEmail(
        url,
        data.email,
        sourceId,
        alertId,
        channelType,
        contactSourceType
      )
      setSignUpState(SIGNED_UP)
      analytics.fireEvent({
        action: googleAnalyticsTrackingOnClick.action ?? 'Email Confirm',
        category: googleAnalyticsTrackingOnClick.category ?? category,
        label: googleAnalyticsTrackingOnClick.label ?? 'Thank You'
      })
    } catch (err) {
      if (err instanceof Error) {
        setSignUpState(SIGN_UP_ERROR)
      }
    } finally {
      setSignUpState(SIGNED_UP)
    }
  }

  useEffect(() => {
    if (gaActionOnView) {
      analytics.fireEvent({
        action: gaActionOnView,
        category: gaCategoryOnView,
        label: gaLabelOnView
      })
    }
  }, [gaActionOnView, gaCategoryOnView, gaLabelOnView])

  return bcfmEmailOptIn ? (
    <BFCMEmailWrapper
      bg="background.darkest"
      color="white"
      borderRadius="xl"
      paddingTop={20}
      paddingBottom={28}
      paddingX={32}
    >
      <OptInCard
        color="white"
        type={EMAIL_TYPE}
        isNameRequired={false}
        heading={
          <Heading as="h4" paddingTop={2}>
            Sign up for Exclusive Black Friday Email-only Coupons
          </Heading>
        }
        buttonText={buttonText}
        successMessage={<ContentfulRichText m={0} body={successHeadline} />}
        failureMessage={invalidEmailError}
        handleFormSubmit={submitHandler}
        placeholderText={placeholder}
        signUpState={signUpState}
        longFormat
      />
    </BFCMEmailWrapper>
  ) : (
    <OptInCard
      type={EMAIL_TYPE}
      isNameRequired={false}
      heading={<ContentfulRichText m={0} body={headline} />}
      subheading={<ContentfulRichText m={0} body={subheadline} />}
      buttonText={buttonText}
      successMessage={<ContentfulRichText m={0} body={successHeadline} />}
      failureMessage={invalidEmailError}
      handleFormSubmit={submitHandler}
      placeholderText={placeholder}
      signUpState={signUpState}
      longFormat
    />
  )
}
