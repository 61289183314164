import React from 'react'
import styled from 'styled-components'
import { Box, Card, Container, Hide, Divider, theme } from 'pcln-design-system'
import { Gpt } from '@pcln/ad-unit'
import HeroImageParallax from '@/components/Heros/HeroImageParallax'
import StyledHeading from '@/components/StyledHeading'
import useFlightDetailsHeadline from '@/components/Flights/useFlightDetailsHeadline'
import useBootstrapData from '@/hooks/useBootstrapData'
import {
  isModifyFlightDetails,
  isRebookFlightDetails
} from '@/shared-utils/flight-rebook-helpers'
import FlightsSearchForm from '../SearchForm/Form'
import FlightsContent from '../Content'
import { onSubmit } from '../SearchForm/utils'
import {
  FLIGHT_TYPE_INFO,
  MULTI_DESTINATION,
  ONE_WAY,
  ROUND_TRIP
} from '../constants'
import PhoneSalesBumper from './PhoneSalesBumper'
import SubHeadline from '../SubHeadline'

const BlockFlex = styled(Box)`
  position: relative;
  z-index: 1;
  ${theme.mediaQueries[3]} {
    display: flex;
  }
`

const HideAdUnit = styled(Hide)`
  flex: none;
  text-align: center;
  position: relative;
  margin: 16px;
  min-height: 100px;
  ${theme.mediaQueries[3]} {
    width: 300px;
    margin: 0 16px;
  }
`

function Desktop() {
  const headline = useFlightDetailsHeadline()
  const { flightRebookOrModifyDetails } = useBootstrapData()
  const isRebook =
    flightRebookOrModifyDetails &&
    isRebookFlightDetails(flightRebookOrModifyDetails)
  const isModify =
    flightRebookOrModifyDetails &&
    isModifyFlightDetails(flightRebookOrModifyDetails)

  return (
    <Box>
      <HeroImageParallax
        isScrollable
        gptId="div-gpt-ad-1510165335112-0"
        slot="/3102/priceline.dart/air_homepage_hero_image"
        mb={4}
      >
        <Container maxWidth={1280}>
          <BlockFlex mx={3} py={[3, null, null, null, 5]}>
            <Card
              borderWidth={0}
              color="background.lightest"
              borderRadius="2xl"
              boxShadowSize="xl"
            >
              <Box p={3}>
                <StyledHeading
                  pb={1}
                  tag="h1"
                  color="text.heading"
                  text={headline}
                  textStyle={['heading3', null, null, null, 'heading2']}
                />
                {isModify && <SubHeadline />}

                <FlightsSearchForm
                  isRebook={isRebook}
                  isModify={isModify}
                  onSubmit={onSubmit}
                  flightTypes={
                    isRebook || isModify
                      ? [
                          FLIGHT_TYPE_INFO[ROUND_TRIP],
                          FLIGHT_TYPE_INFO[ONE_WAY]
                        ]
                      : [
                          FLIGHT_TYPE_INFO[ROUND_TRIP],
                          FLIGHT_TYPE_INFO[ONE_WAY],
                          FLIGHT_TYPE_INFO[MULTI_DESTINATION]
                        ]
                  }
                />
              </Box>
              {!isModify && !isRebook && (
                <Hide xs sm>
                  <Divider m={0} />
                  <Box
                    color="background.light"
                    borderRadius="2xl"
                    boxShadowSize="xl"
                    rounded="bottom"
                  >
                    <PhoneSalesBumper />
                  </Box>
                </Hide>
              )}
            </Card>
            <HideAdUnit xs sm>
              <Gpt render={() => <div id="div-gpt-ad-1510165683878-0" />} />
            </HideAdUnit>
          </BlockFlex>
        </Container>
      </HeroImageParallax>
      <Hide xs sm color="background.lightest">
        <FlightsContent />
      </Hide>
    </Box>
  )
}

export default Desktop
