import React from 'react'
import { Container, Flex } from 'pcln-design-system'

export default function AdBanner({
  children
}: {
  children?: React.ReactNode | React.ReactNode[]
}) {
  return (
    <Flex alignItems="center" justifyContent="center">
      <Container maxWidth={728}>{children}</Container>
    </Flex>
  )
}
