import React from 'react'
import { Container } from 'pcln-design-system'
import AdBanner from '@/components/AdBanner'
import { AdBox, AdContent } from '@/components/AdContent'
import AppDownloadWrapper from '@/components/AppDownloadWrapper'
import EmailSignUp from '@/components/EmailSignUp'
import FlyPriceDrop from '@/components/FlyPriceDrop'
import { ANALYTICS_CATEGORY_FLIGHTS } from '@/constants/analytics'
import FlightsTripActivity from '@/components/TripActivity/FlightsTripActivity'
import useGetMostRecentFlightSearch from '@/components/TripActivity/FlightsTripActivity/hooks/useGetMostRecentFlightSearch'
import LayoutComponentWrapper from '@/components/LayoutComponentWrapper'

function Content() {
  const mostRecentFlightSearchData = useGetMostRecentFlightSearch()

  return (
    <Container size="xl" mb={5}>
      {mostRecentFlightSearchData.length > 0 && (
        <FlightsTripActivity
          mostRecentFlightSearchData={mostRecentFlightSearchData}
        />
      )}
      <FlyPriceDrop />
      <LayoutComponentWrapper topGap>
        <EmailSignUp
          sourceId="LANDING_AIR"
          category={ANALYTICS_CATEGORY_FLIGHTS}
        />
        <AdContent>
          <AdBox type="primary">
            <div data-testid="flight-ad-1" id="div-gpt-ad-1510165758104-0" />
          </AdBox>
          <AdBox type="secondary">
            <div data-testid="flight-ad-2" id="div-gpt-ad-1510169021467-0" />
          </AdBox>
          <AdBox type="tertiary">
            <div data-testid="flight-ad-3" id="div-gpt-ad-1510169161509-0" />
          </AdBox>
        </AdContent>
        <AppDownloadWrapper category={ANALYTICS_CATEGORY_FLIGHTS} />
        <AdBanner>
          <div data-testid="flight-ad-4" id="div-gpt-ad-1510169283152-0" />
        </AdBanner>
      </LayoutComponentWrapper>
    </Container>
  )
}
export default Content
