import React from 'react'
import { Text, GenericBanner } from 'pcln-design-system'

export default function PhoneSalesBumper() {
  return (
    <GenericBanner
      p={3}
      text={
        <Text fontSize={1} textAlign="center" color="text.dark">
          Looking for international flight deals? Call us at 1 (833) 203-5879
        </Text>
      }
      color="background.light"
      borderRadius="xl"
    />
  )
}
