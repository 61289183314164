import React from 'react'
import { Flex } from 'pcln-design-system'
import AdBox from './AdBox'

function AdContent({ children }: { children: React.ReactNode }) {
  return (
    <Flex alignItems="center" justifyContent="center">
      {children}
    </Flex>
  )
}

export { AdContent, AdBox }
