import React from 'react'
import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'
import { Box, Flex } from 'pcln-design-system'

const PrimaryWrapper = styled(Flex)`
  justify-content: center;
  height: 250px;
  width: 100%;
  margin: 0 16px 0 0;
  max-width: 474px;
  min-width: 300px;
`

const SecondaryWrapper = styled(Flex)`
  display: none;

  ${themeGet('mediaQueries.xl')} {
    display: block;
    justify-content: center;
    height: 250px;
    width: 100%;
    margin: 0 16px 0 0;
    max-width: 474px;
    min-width: 300px;
  }
`

const TertiaryWrapper = styled(Flex)`
  height: 250px;
  width: 300px;
`

const AdBoxWrapper = {
  primary: PrimaryWrapper,
  secondary: SecondaryWrapper,
  tertiary: TertiaryWrapper
}

export default function AdBox({
  children,
  type
}: {
  children?: React.ReactNode | React.ReactNode[]
  type: keyof typeof AdBoxWrapper
}) {
  const Wrapper = AdBoxWrapper[type]
  return (
    <Wrapper>
      <Box width={1}>{children}</Box>
    </Wrapper>
  )
}
